/**
 * File library.js
 * 
 * Custom scripts.
 */

(function($) {

	// Registering GSAP plugin

	gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

	// Global Variables

	var siteLogo = $('.site-logo'),
		siteTools = $('.site-tools'),
		headerHeight = ( siteLogo.outerHeight() > siteTools.outerHeight() ) ? siteLogo.outerHeight() : siteTools.outerHeight(),
		menuButton = $('.menu-button'),
		claim = $('.header-image .claim'),
		rows = $('#vertical-rows');
	
	// All-click objects

	function allClick() {
		var clickbox = $('.allclick');
		clickbox.each(function() {
			var $this = $(this);
			$this.css('cursor', 'pointer');
			$this.find('a').on('click', function(e) {
				e.preventDefault();
			});
			
			if ($this.find('a').attr('target') == '_blank') {
				$this.on('click', function(e) {
					window.open($('a', this).attr('href'));
				});
			} else {
				$this.on('click', function(e) {
					window.location.href = $('a', this).attr('href');
				});
			}
		});
	}

	// Smooth scrolling

	function smoothScroll() {
		$("a[href*='#']").not("[href='#'],[data-toggle]").on('click', function(e) {
			if (this.hash !== "") {
				e.preventDefault();

				var hash = this.hash;
				$('html, body').animate({
					scrollTop: $(hash).offset().top - headerHeight
				}, 1000);
			}
		});
	}

	// Collapse element

	function collapseItem() {
		var links = $('.collapse-link'),
			items = $('.collapse-content'),
			noCollapse = $('.no-collapse'),
			space = $(window).width() >= 1024 ? 160 : 90;
		
		noCollapse.css({ 'height': noCollapse.prop( 'scrollHeight' ) + space + 'px', 'opacity': 1 }); 
		
		links.each(function() {
			var link = $(this);	
			link.on('click', function(e) {
				e.preventDefault();

				links.removeClass( 'active' );
				link.toggleClass( 'active' );

				items.each(function() {
					$(this).css({ 'height': 0, 'opacity': 0 });
				});

				var target = $(link.data( 'target' )),
					hTarget = target.prop( 'scrollHeight' ) + space + 'px';
				target.css({ 'height': hTarget, 'opacity': 1 });
			});
		});
	}

	// Code to run when the document is ready

	$( document ).ready(function() {

		allClick();
		smoothScroll();
		collapseItem();

		// Adjust padding for page without header images or with small header image
		
		if ( $('body').hasClass('no-header-images') || $('body').hasClass('header-image-small') ) {
			$('#page').css( 'padding-top', headerHeight );

			if ( $(window).width() >= 1024 ) {
				siteLogo.css( 'top', -headerHeight );
			}
		}

		// Intro Animations

		$('#page').addClass( 'init' );

		// Inhibit click on primary menu items

		$('#primary-menu').find('a[href="#"]').each(function() {
			$(this).on('click', function(e) {
				e.preventDefault();

				$('#primary-menu .menu-item > a').not(this).next('.sub-menu').removeClass('open');

				if ( $(this).next('.sub-menu').hasClass('open') ) {
					$(this).next('.sub-menu').removeClass('open');
				} else {
					$(this).next('.sub-menu').addClass('open');
				}
			});
		});

		// Manage images menu

		$('#primary-menu > li').on('mouseenter', function() {
			var item = $(this);

			$('.menu-image').removeClass('visible');
			$('.menu-image:eq(' + (item.index() + 1) + ')').addClass('visible');
		});

		// Background size parallax images

		$('.parallax').each(function() {
			var size = ( $(this).width() * 100 );

			if ( $(window).width() > 1920 ) {
				size = size / 1920;
			} else {
				size = size / $(window).width();
			}
			size = size + '%';

			$(this).css( 'background-size', size );
		});

		// Items filtering

		$('.item-filters .filter').on('click', function(event) {
			event.preventDefault();
			$('.item-filters .filter').removeClass('active');
			$(this).addClass('active');

			var mix = $(this).data('filter');
			var items = $('[data-mix]');

			if ( mix != "all" ) {
				items.hide();
				$('[data-mix*="' + mix + '"]').fadeIn(500);
			} else {
				items.fadeIn(500);
			}
		});

	});

	// Code to run when the entire page is ready

	$( window ).on( 'load', function() {

		/* GSAP Animations - Begin */

		var mm = gsap.matchMedia();

		mm.add( '(min-width: 1024px)', function() {
			// Horizontal scrolling
			if ( $('body').hasClass('horizontal') ) {
				var holder = document.getElementById('content');
				
				gsap.to(
					holder,
					{
						x: () => -( holder.scrollWidth - document.documentElement.clientWidth ) + 'px',
						ease: 'none',
						scrollTrigger: {
							trigger: holder,
							pin: true,
							scrub: 1,
							end: () => '+=' + holder.offsetWidth,
							invalidateOnRefresh: true
						}
					}
				);

				// Scroll to panel
				var links = gsap.utils.toArray('.to-scroll');

				if ( links.length > 0 ) {
					links.forEach((link) => {
						link.addEventListener('click', function(e) {
							e.preventDefault();

							const elm = document.querySelector(link.getAttribute('href'));
							const off = elm.offsetLeft * ( holder.scrollWidth / ( holder.scrollWidth - window.innerWidth ) );

							gsap.to(
								window,
								{
									scrollTo: off,
									ease: 'Power2.inOut',
									duration: 1.5
								}
							);

						});
					});
				}
			}

			// Gallery revealed
			var gallery = $('#gallery'),
				galleryItem = $('.gallery-grid');

			if ( gallery.length > 0 ) {
				gsap.to(
					galleryItem,
					{
						scale: 0.4,
						ease: 'none',
						scrollTrigger: {
							trigger: galleryItem,
							start: 'top top',
							scrub: 0.8,
							pin: true
						}
					}
				);
			}
		});

		// Menu
		var menu = gsap.timeline({
			paused: true,
			onStart: function() {
				$('body').addClass( 'open-menu' );
			},
			onReverseComplete: function() {
				$('body').removeClass( 'open-menu' );
			}
		});

		menu.to(
			'#menu-offcanvas',
			{
				duration: 1,
				x: 0,
				opacity: 1,
				ease: 'Power2.inOut',
				stagger: 0.1
			}
		).to(
			'.images-navigation',
			{
				duration: 1,
				opacity: 1,
				ease: 'Power2.inOut',
				stagger: 0.1
			}
		).to(
			'.menu-item',
			{
				duration: 0.5,
				opacity: 1,
				ease: 'Power2.inOut',
				stagger: 0.1
			},
			'-=0.1'
		).to(
			'.menu-bottom',
			{
				opacity: 1,
				ease: 'Power2.inOut'
			}
		);

		// Logo fading
		if ( siteLogo.length > 0 && ( ! $('body').hasClass('horizontal') || $(window).width() < 1024 ) ) {
			gsap.to(
				siteLogo,
				{
					duration: 0.1,
					opacity: 0,
					pointerEvents: 'none',
					scrollTrigger: {
						trigger: siteLogo,
						start: 'top top',
						toggleActions: 'play none none reverse'
					}
				}
			);
		}

		// Claim fading
		if ( claim.length > 0 ) {
			gsap.to(
				claim,
				{
					duration: 1,
					opacity: 0,
					scrollTrigger: {
						trigger: claim,
						start: 'top top',
						toggleActions: 'play none none reverse'
					}
				}
			);
		}

		// Tools
		if ( $('body').hasClass('header-image-full') ) {
			gsap.to(
				siteTools,
				{
					scrollTrigger: {
						trigger: '.header-image.fixed',
						start: 'top bottom-=' + headerHeight,
						toggleClass: {
							targets: siteTools,
							className: 'on-image'
						}
					}
				}
			);
		}

		// Vertical rows
		if ( rows.length > 0 ) {
			gsap.from(
				rows,
				{
					scaleY: 0, 
					ease: 'none',
					scrollTrigger: {
						trigger: '#content',
						scrub: 0.3
					}
				}
			);
		}

		// Lines (scale X)
		var lines = gsap.utils.toArray('.line');

		if ( lines.length > 0 ) {
			lines.forEach((line) => {
				var delay = line.getAttribute('data-delay'),
					scroll = line.getAttribute('data-scroll'),
					start = 'top center';

				if ( scroll == 'no' ) start = 'top bottom';

				gsap.from(
					line,
					{
						delay: delay,
						scrollTrigger: {
							trigger: line,
							start: start,
							toggleClass: 'active',
							once: true
						}
					}
				);
			});
		}

		// Titles (sliding up)
		var titles = gsap.utils.toArray('.slide');

		if ( titles.length > 0 ) {
			titles.forEach((title) => {
				var delay = title.getAttribute('data-delay'),
					scroll = title.getAttribute('data-scroll'),
					start = 'top center';

				if ( scroll == 'no' ) start = 'top bottom';

				title.parentNode.style.overflow = 'hidden';

				gsap.from(
					title,
					{
						delay: delay,
						duration: 1.5,
						yPercent: 120,
						scrollTrigger: {
							trigger: title,
							start: start
						}
					}
				);
			});
		}

		// Texts (fading up)
		var texts = gsap.utils.toArray('.fade');

		if ( texts.length > 0 ) {
			texts.forEach((text) => {
				var delay = text.getAttribute('data-delay'),
					scroll = text.getAttribute('data-scroll'),
					start = 'top center';

				if ( scroll == 'no' ) start = 'top bottom';

				gsap.from(
					text,
					{
						delay: delay,
						duration: 1,
						autoAlpha: 0,
						y: 50,
						scrollTrigger: {
							trigger: text,
							start: start
						}
					}
				);
			});
		}

		// Images reveal
		var containers = gsap.utils.toArray('.reveal');

		if ( containers.length > 0 ) {
			containers.forEach((container) => {
				var image = container.querySelector('img'),
					tl = gsap.timeline({
						scrollTrigger: {
							trigger: container,
							//toggleActions: "restart none none reset"
						}
					});
				
				tl.set(
					container,
					{
						autoAlpha: 1
					}
				);

				tl.from(
					container,
					1.5,
					{
						xPercent: -100,
						ease: 'Power2.easeOut'
					}
				);

				tl.from(
					image,
					1.5,
					{
						xPercent: 100,
						scale: 1.3,
						delay: -1.5,
						ease: 'Power2.easeOut'
					}
				);
			});
		}

		// Reasons item
		var reasons = gsap.utils.toArray('#reasons .reason');

		if ( reasons.length > 0 ) {
			reasons.forEach((reason) => {
				gsap.to(
					reason,
					{
						scrollTrigger: {
							trigger: reason,
							start: 'top center',
							toggleClass: 'active',
							once: true
						}
					}
				);
			});
		}

		// Single letter animation
		function createTextAnimations() {
			$('.letter-animation').each(function(index) {
				let triggerElement = $(this);
				let targetElement = $(this).find('span');
			
				let tl = gsap.timeline({
					scrollTrigger: {
						trigger: triggerElement,
						start: 'top bottom',
						end: 'bottom top'
					}
				});

				tl.from(targetElement, {
					duration: 1,
					yPercent: 60,
					opacity: 0,
					rotationX: -90,
					stagger: {
						amount: 0.7,
						from: '0'
					}
				});
			});
		}

		createTextAnimations();

		// Services item
		var services = gsap.utils.toArray('.other-services .item');

		if ( services.length > 0 ) {
			services.forEach((service) => {
				gsap.to(
					service,
					{
						scrollTrigger: {
							trigger: service,
							start: 'top bottom',
							toggleClass: 'active',
							once: true
						}
					}
				);
			});
		}

		/* GSAP Animations - End */

		// Hide/show menu off canvas

		menuButton.on('click', function(e) {
			e.preventDefault();
			
			$(this).toggleClass('collapsed');

			if ( ! $(this).hasClass('collapsed') ) {
				$('#primary-menu .menu-item > a').next('.sub-menu').removeClass('open');
				menu.play();
			} else {
				menu.reverse();
			}
		});

		// Equal Heights
		if ( $(window).width() >= 1024 ) {
			$('.equalHeight').equalHeights();
		}

	});

	// Code to run when the browser window has been resized

	var timer_id;

	$( window ).on( 'resize', function() {

		clearTimeout(timer_id);
		timer_id = setTimeout(function() {

			if ( $(window).width() >= 1024 ) {
				if ( $('body').hasClass('no-header-images') || $('body').hasClass('header-image-small') ) {
					siteLogo.css( 'top', -headerHeight );
				}

				// Equal Heights
				$('.equalHeight').equalHeights();
			}
			
		}, 300);

		// Background size parallax images

		$('.parallax').each(function() {
			var size = ( $(this).width() * 100 );

			if ( $(window).width() > 1920 ) {
				size = size / 1920;
			} else {
				size = size / $(window).width();
			}
			size = size + '%';

			$(this).css( 'background-size', size );
		});

	});

})(jQuery);

// Magic mouse

if ( document.documentElement.clientWidth > 1199 ) {
	var options = {
		"cursorOuter": "circle-basic",
		"hoverEffect": "circle-move",
		"hoverItemMove": false,
		"defaultCursor": true,
		"outerWidth": 20,
		"outerHeight": 20
	};

	magicMouse(options);
}

/* Swiper - Begin */

// Rooms slideshow
const roomsImages = new Swiper('#rooms .images-slideshow', {
	speed: 1000,
	effect: 'fade',
	fadeEffect: {
		crossFade: true,
	},
	navigation: {
		nextEl: '#rooms .swiper-button-next',
		prevEl: '#rooms .swiper-button-prev',
	},
});

const roomsContents = new Swiper('#rooms .contents-slideshow', {
	speed: 1000,
	effect: 'fade',
	fadeEffect: {
		crossFade: true
	},
	allowTouchMove: false,
});

roomsContents.controller.control = roomsImages;
roomsImages.controller.control = roomsContents;

const roomsSlider = new Swiper('#rooms-list .rooms-slideshow', {
	speed: 1000,
	effect: 'fade',
	fadeEffect: {
		crossFade: true,
	},
	navigation: {
		nextEl: '#rooms-list .swiper-button-next',
		prevEl: '#rooms-list .swiper-button-prev',
	},
});

// Offers slideshow
const offersSlider = new Swiper('#offers-list .offers-slideshow', {
	speed: 1000,
	effect: 'fade',
	fadeEffect: {
		crossFade: true,
	},
	navigation: {
		nextEl: '#offers-list .swiper-button-next',
		prevEl: '#offers-list .swiper-button-prev',
	},
});

// Reviews slideshow
const reviewsSlider = new Swiper('#reviews .reviews-slideshow', {
	speed: 1000,
	effect: 'fade',
	fadeEffect: {
		crossFade: true,
	},
	navigation: {
		nextEl: '#reviews .swiper-button-next',
		prevEl: '#reviews .swiper-button-prev',
	},
});

// Social slideshow
const socialSlider = new Swiper('#social .social-slideshow', {
	loop: true,
	slidesPerView: 1,
	speed: 1000,
	autoplay: {
		delay: 2000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	breakpoints: {
		768: {
			slidesPerView: 3
		},
		992: {
			slidesPerView: 4
		}
	},
});

// Services slideshow
const slides = document.querySelectorAll('#services-list .swiper-slide');
const servicesSlider = new Swiper('#services-list .services-slideshow', {
	speed: 1000,
	effect: 'fade',
	fadeEffect: {
		crossFade: true,
	},
	pagination: {
		el: '.swiper-pagination',
		clickable: true,
		renderBullet: function(index, className) {
			return '<div class="' + className + '">' + slides[index].dataset.name + "</div>";
		},
	},
	navigation: {
		nextEl: '#services-list .swiper-button-next',
		prevEl: '#services-list .swiper-button-prev',
	},
});

/* Swiper - End */
